<template>
  <div class="px-2 py-5">
    <h1 class="title">SCORAD</h1>
    <b-message type="is-info">
      Seleccione el % afectado de cada parte del cuerpo en los apartados
      inferiores y a continuación la intensidad de los diferentes síntomas
      detectados en el/la paciente.
    </b-message>
    <section>
      <h1 class="title has-text-centered">Zona corporal</h1>

      <div class="block">
        <span class="question">
          1. Cabeza y cuello.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneHeadNeck"
              :name="questions.ZONA.SCORAD_ZONA_CABEZA_CUELLO"
              :native-value="
                answers.ZONA.ZONA_CABEZA_CUELLO.SCORAD_ZONA_CABEZA_CUELLO_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneHeadNeck"
              :name="questions.ZONA.SCORAD_ZONA_CABEZA_CUELLO"
              :native-value="
                answers.ZONA.ZONA_CABEZA_CUELLO.SCORAD_ZONA_CABEZA_CUELLO_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneHeadNeck"
              :name="questions.ZONA.SCORAD_ZONA_CABEZA_CUELLO"
              :native-value="
                answers.ZONA.ZONA_CABEZA_CUELLO.SCORAD_ZONA_CABEZA_CUELLO_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneHeadNeck"
              :name="questions.ZONA.SCORAD_ZONA_CABEZA_CUELLO"
              :native-value="
                answers.ZONA.ZONA_CABEZA_CUELLO.SCORAD_ZONA_CABEZA_CUELLO_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneHeadNeck"
              :name="questions.ZONA.SCORAD_ZONA_CABEZA_CUELLO"
              :native-value="
                answers.ZONA.ZONA_CABEZA_CUELLO.SCORAD_ZONA_CABEZA_CUELLO_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          2. Extremidades superiores (izquierda).
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsLeft"
              :name="
                questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
              "
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsLeft"
              :name="
                questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
              "
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsLeft"
              :name="
                questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
              "
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsLeft"
              :name="
                questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
              "
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsLeft"
              :name="
                questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
              "
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          3. Extremidades superiores (derecha).
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_DERECHA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_DERECHA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_DERECHA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_DERECHA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneSueperiorLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_DERECHA
                  .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          4. Miembros inferiores (izquierda).
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsLeft"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_IZQUIERDA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsLeft"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_IZQUIERDA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsLeft"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_IZQUIERDA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsLeft"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_IZQUIERDA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsLeft"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_IZQUIERDA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          5. Miembros inferiores (derecha).
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_DERECHA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_DERECHA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_DERECHA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_DERECHA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneLowerLimbsRight"
              :name="questions.ZONA.SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA"
              :native-value="
                answers.ZONA.ZONA_MIEMBROS_INFERIORES_DERECHA
                  .SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          6. Tronco anterior.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneTruk"
              :name="questions.ZONA.SCORAD_ZONA_TRONCO_ANTERIOR"
              :native-value="
                answers.ZONA.ZONA_TRONCO_ANTERIOR.SCORAD_ZONA_TRONCO_ANTERIOR_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneTruk"
              :name="questions.ZONA.SCORAD_ZONA_TRONCO_ANTERIOR"
              :native-value="
                answers.ZONA.ZONA_TRONCO_ANTERIOR.SCORAD_ZONA_TRONCO_ANTERIOR_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneTruk"
              :name="questions.ZONA.SCORAD_ZONA_TRONCO_ANTERIOR"
              :native-value="
                answers.ZONA.ZONA_TRONCO_ANTERIOR.SCORAD_ZONA_TRONCO_ANTERIOR_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneTruk"
              :name="questions.ZONA.SCORAD_ZONA_TRONCO_ANTERIOR"
              :native-value="
                answers.ZONA.ZONA_TRONCO_ANTERIOR.SCORAD_ZONA_TRONCO_ANTERIOR_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneTruk"
              :name="questions.ZONA.SCORAD_ZONA_TRONCO_ANTERIOR"
              :native-value="
                answers.ZONA.ZONA_TRONCO_ANTERIOR.SCORAD_ZONA_TRONCO_ANTERIOR_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          7. Espalda.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneBack"
              :name="questions.ZONA.SCORAD_ZONA_ESPALDA"
              :native-value="answers.ZONA.ZONA_ESPALDA.SCORAD_ZONA_ESPALDA_R1"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneBack"
              :name="questions.ZONA.SCORAD_ZONA_ESPALDA"
              :native-value="answers.ZONA.ZONA_ESPALDA.SCORAD_ZONA_ESPALDA_R2"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneBack"
              :name="questions.ZONA.SCORAD_ZONA_ESPALDA"
              :native-value="answers.ZONA.ZONA_ESPALDA.SCORAD_ZONA_ESPALDA_R3"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneBack"
              :name="questions.ZONA.SCORAD_ZONA_ESPALDA"
              :native-value="answers.ZONA.ZONA_ESPALDA.SCORAD_ZONA_ESPALDA_R4"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneBack"
              :name="questions.ZONA.SCORAD_ZONA_ESPALDA"
              :native-value="answers.ZONA.ZONA_ESPALDA.SCORAD_ZONA_ESPALDA_R5"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          8. Genitales.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneGenitals"
              :name="questions.ZONA.SCORAD_ZONA_GENITALES"
              :native-value="
                answers.ZONA.ZONA_GENITALES.SCORAD_ZONA_GENITALES_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneGenitals"
              :name="questions.ZONA.SCORAD_ZONA_GENITALES"
              :native-value="
                answers.ZONA.ZONA_GENITALES.SCORAD_ZONA_GENITALES_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              25%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneGenitals"
              :name="questions.ZONA.SCORAD_ZONA_GENITALES"
              :native-value="
                answers.ZONA.ZONA_GENITALES.SCORAD_ZONA_GENITALES_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              50%
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="zoneGenitals"
              :name="questions.ZONA.SCORAD_ZONA_GENITALES"
              :native-value="
                answers.ZONA.ZONA_GENITALES.SCORAD_ZONA_GENITALES_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              75%
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="zoneGenitals"
              :name="questions.ZONA.SCORAD_ZONA_GENITALES"
              :native-value="
                answers.ZONA.ZONA_GENITALES.SCORAD_ZONA_GENITALES_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              100%
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <h1 class="title has-text-centered">Signos clinicos</h1>

      <div class="block">
        <span class="question">
          1. Rojez.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="signsReedness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_ROJEZ"
              :native-value="answers.SIGNOS.SIGNOS_ROJEZ.SCORAD_SIGNOS_ROJEZ_R1"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Ninguno
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsReedness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_ROJEZ"
              :native-value="answers.SIGNOS.SIGNOS_ROJEZ.SCORAD_SIGNOS_ROJEZ_R2"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Leve
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsReedness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_ROJEZ"
              :native-value="answers.SIGNOS.SIGNOS_ROJEZ.SCORAD_SIGNOS_ROJEZ_R3"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Moderado
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsReedness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_ROJEZ"
              :native-value="answers.SIGNOS.SIGNOS_ROJEZ.SCORAD_SIGNOS_ROJEZ_R4"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Grave
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          2. Hinchazón.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="signsSwelling"
              :name="questions.SIGNOS.SCORAD_SIGNOS_HINCHAZON"
              :native-value="
                answers.SIGNOS.SIGNOS_HINCHAZON.SCORAD_SIGNOS_HINCHAZON_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Ninguno
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsSwelling"
              :name="questions.SIGNOS.SCORAD_SIGNOS_HINCHAZON"
              :native-value="
                answers.SIGNOS.SIGNOS_HINCHAZON.SCORAD_SIGNOS_HINCHAZON_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Leve
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsSwelling"
              :name="questions.SIGNOS.SCORAD_SIGNOS_HINCHAZON"
              :native-value="
                answers.SIGNOS.SIGNOS_HINCHAZON.SCORAD_SIGNOS_HINCHAZON_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Moderado
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsSwelling"
              :name="questions.SIGNOS.SCORAD_SIGNOS_HINCHAZON"
              :native-value="
                answers.SIGNOS.SIGNOS_HINCHAZON.SCORAD_SIGNOS_HINCHAZON_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Grave
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          3. Costras.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="signsScabs"
              :name="questions.SIGNOS.SCORAD_SIGNOS_COSTRAS"
              :native-value="
                answers.SIGNOS.SIGNOS_COSTRAS.SCORAD_SIGNOS_COSTRAS_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Ninguno
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsScabs"
              :name="questions.SIGNOS.SCORAD_SIGNOS_COSTRAS"
              :native-value="
                answers.SIGNOS.SIGNOS_COSTRAS.SCORAD_SIGNOS_COSTRAS_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Leve
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsScabs"
              :name="questions.SIGNOS.SCORAD_SIGNOS_COSTRAS"
              :native-value="
                answers.SIGNOS.SIGNOS_COSTRAS.SCORAD_SIGNOS_COSTRAS_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Moderado
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsScabs"
              :name="questions.SIGNOS.SCORAD_SIGNOS_COSTRAS"
              :native-value="
                answers.SIGNOS.SIGNOS_COSTRAS.SCORAD_SIGNOS_COSTRAS_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Grave
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          4. Marcas.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="signsMarks"
              :name="questions.SIGNOS.SCORAD_SIGNOS_MARCAS"
              :native-value="
                answers.SIGNOS.SIGNOS_MARCAS.SCORAD_SIGNOS_MARCAS_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Ninguno
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsMarks"
              :name="questions.SIGNOS.SCORAD_SIGNOS_MARCAS"
              :native-value="
                answers.SIGNOS.SIGNOS_MARCAS.SCORAD_SIGNOS_MARCAS_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Leve
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsMarks"
              :name="questions.SIGNOS.SCORAD_SIGNOS_MARCAS"
              :native-value="
                answers.SIGNOS.SIGNOS_MARCAS.SCORAD_SIGNOS_MARCAS_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Moderado
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsMarks"
              :name="questions.SIGNOS.SCORAD_SIGNOS_MARCAS"
              :native-value="
                answers.SIGNOS.SIGNOS_MARCAS.SCORAD_SIGNOS_MARCAS_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Grave
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          5. Liquenificacion.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="signsLiquenification"
              :name="questions.SIGNOS.SCORAD_SIGNOS_LIQUENIFICACION"
              :native-value="
                answers.SIGNOS.SIGNOS_LIQUENIFICACION
                  .SCORAD_SIGNOS_LIQUENIFICACION_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Ninguno
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsLiquenification"
              :name="questions.SIGNOS.SCORAD_SIGNOS_LIQUENIFICACION"
              :native-value="
                answers.SIGNOS.SIGNOS_LIQUENIFICACION
                  .SCORAD_SIGNOS_LIQUENIFICACION_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Leve
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsLiquenification"
              :name="questions.SIGNOS.SCORAD_SIGNOS_LIQUENIFICACION"
              :native-value="
                answers.SIGNOS.SIGNOS_LIQUENIFICACION
                  .SCORAD_SIGNOS_LIQUENIFICACION_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Moderado
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsLiquenification"
              :name="questions.SIGNOS.SCORAD_SIGNOS_LIQUENIFICACION"
              :native-value="
                answers.SIGNOS.SIGNOS_LIQUENIFICACION
                  .SCORAD_SIGNOS_LIQUENIFICACION_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Grave
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          6. Sequedad.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="signsDryness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_SEQUEDAD"
              :native-value="
                answers.SIGNOS.SIGNOS_SEQUEDAD.SCORAD_SIGNOS_SEQUEDAD_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Ninguno
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsDryness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_SEQUEDAD"
              :native-value="
                answers.SIGNOS.SIGNOS_SEQUEDAD.SCORAD_SIGNOS_SEQUEDAD_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Leve
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsDryness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_SEQUEDAD"
              :native-value="
                answers.SIGNOS.SIGNOS_SEQUEDAD.SCORAD_SIGNOS_SEQUEDAD_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Moderado
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="signsDryness"
              :name="questions.SIGNOS.SCORAD_SIGNOS_SEQUEDAD"
              :native-value="
                answers.SIGNOS.SIGNOS_SEQUEDAD.SCORAD_SIGNOS_SEQUEDAD_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Grave
            </b-radio>
          </div>
        </div>
      </div>

      <hr />

      <h1 class="title has-text-centered">Sintomas subjetivos</h1>

      <div class="block">
        <span class="question">
          1. Prurito (comezon).
        </span>

        <div class="columns mt-2">
          <div class="column has-text-left">
            <strong>Sin comezón</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              1
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              2
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              3
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              4
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R6
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              5
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R7
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              6
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R8
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              7
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R9
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              8
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R10
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              9
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomItch"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R11
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              10
            </b-radio>
          </div>
          <div class="column has-text-left">
            <strong>Peor comezón </strong>
          </div>
        </div>
      </div>

      <hr />

      <div class="block">
        <span class="question">
          2. Insomnio.
        </span>

        <div class="columns mt-2">
          <div class="column has-text-left">
            <strong>Sin insomnio</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R1
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              0
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R2
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              1
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R3
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              2
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R4
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              3
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R5
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              4
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R6
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              5
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R7
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              6
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R8
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              7
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R9
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              8
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R10
              "
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              9
            </b-radio>
          </div>

          <div class="column">
            <b-radio
              v-model="symptomInsomnia"
              :name="questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO"
              :native-value="
                answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R11
              "
              @input="saveTermporalSulrvey"
            >
              10
            </b-radio>
          </div>
          <div class="column has-text-left">
            <strong>Peor insomnio</strong>
          </div>
        </div>
      </div>

      <hr />

      <b-message type="is-info">
        <strong>Rango de resultados</strong>
        <br />
        0 - Ninguno
        <br />
        0.1 a 15 - Leve
        <br />
        15.1 a 40 - Moderado
        <br />
        40.1 a 103 - Moderado
      </b-message>
      <div class="block has-text-centered title mt-5">
        El resultado es: {{ score }}
        <span>({{ scoreText }})</span>
      </div>
      <div class="buttons is-centered" v-if="!queryMode">
        <b-button class="clear-button" icon-right="block-helper" @click="clear"
          >Limpiar</b-button
        >
        <b-button class="save-button" icon-right="check-bold" @click="validate"
          >Guardar</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { SCORAD as questions } from "../../../data/questions";
import { SCORAD as answers } from "../../../data/answers";
export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: true,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      zoneHeadNeck: "",
      zoneSueperiorLimbsLeft: "",
      zoneSueperiorLimbsRight: "",
      zoneLowerLimbsLeft: "",
      zoneLowerLimbsRight: "",
      zoneTruk: "",
      zoneBack: "",
      zoneGenitals: "",
      signsReedness: "",
      signsSwelling: "",
      signsScabs: "",
      signsMarks: "",
      signsLiquenification: "",
      signsDryness: "",
      symptomItch: "",
      symptomInsomnia: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "ScoradList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.zoneHeadNeck = this.$route.params.temporalData.zoneHeadNeck;
      this.zoneSueperiorLimbsLeft = this.$route.params.temporalData.zoneSueperiorLimbsLeft;
      this.zoneSueperiorLimbsRight = this.$route.params.temporalData.zoneSueperiorLimbsRight;
      this.zoneLowerLimbsLeft = this.$route.params.temporalData.zoneLowerLimbsLeft;
      this.zoneLowerLimbsRight = this.$route.params.temporalData.zoneLowerLimbsRight;
      this.zoneTruk = this.$route.params.temporalData.zoneTruk;
      this.zoneBack = this.$route.params.temporalData.zoneBack;
      this.zoneGenitals = this.$route.params.temporalData.zoneGenitals;
      this.signsReedness = this.$route.params.temporalData.signsReedness;
      this.signsSwelling = this.$route.params.temporalData.signsSwelling;
      this.signsScabs = this.$route.params.temporalData.signsScabs;
      this.signsMarks = this.$route.params.temporalData.signsMarks;
      this.signsLiquenification = this.$route.params.temporalData.signsLiquenification;
      this.signsDryness = this.$route.params.temporalData.signsDryness;
      this.symptomItch = this.$route.params.temporalData.symptomItch;
      this.symptomInsomnia = this.$route.params.temporalData.symptomInsomnia;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      let genitals =
        this.zoneGenitals == "R2" || this.zoneGenitals == "R2A"
          ? 0.1
          : this.zoneGenitals;

      return (
        (this.zoneHeadNeck || 0) +
        (this.zoneSueperiorLimbsLeft || 0) +
        (this.zoneSueperiorLimbsRight || 0) +
        (this.zoneLowerLimbsLeft || 0) +
        (this.zoneLowerLimbsRight || 0) +
        (this.zoneTruk || 0) +
        (this.zoneBack || 0) +
        (genitals || 0) +
        (this.signsReedness || 0) +
        (this.signsSwelling || 0) +
        (this.signsScabs || 0) +
        (this.signsMarks || 0) +
        (this.signsLiquenification || 0) +
        (this.signsDryness || 0) +
        (this.symptomItch || 0) +
        (this.symptomInsomnia || 0)
      );
    },
    scoreText() {
      let text = "";

      if (parseInt(this.score) == 0) {
        text = "Ninguno";
      }

      if (this.score >= 0.1 && this.score <= 15) {
        text = "Leve";
      }

      if (this.score >= 15.1 && this.score <= 40) {
        text = "Moderado";
      }

      if (this.score >= 40.1 && this.score <= 103) {
        text = "Grave";
      }

      return text;
    },
    isIncompleted() {
      return (
        this.zoneHeadNeck === "" ||
        this.zoneSueperiorLimbsLeft === "" ||
        this.zoneSueperiorLimbsRight === "" ||
        this.zoneLowerLimbsLeft === "" ||
        this.zoneLowerLimbsRight === "" ||
        this.zoneTruk === "" ||
        this.zoneBack === "" ||
        this.zoneGenitals === "" ||
        this.signsReedness === "" ||
        this.signsSwelling === "" ||
        this.signsScabs === "" ||
        this.signsMarks === "" ||
        this.signsLiquenification === "" ||
        this.signsDryness === "" ||
        this.symptomItch === "" ||
        this.symptomInsomnia === ""
      );
    },
    isZoneComplete() {
      return (
        this.zoneHeadNeck === "" ||
        this.zoneSueperiorLimbsLeft === "" ||
        this.zoneSueperiorLimbsRight === "" ||
        this.zoneLowerLimbsLeft === "" ||
        this.zoneLowerLimbsRight === "" ||
        this.zoneTruk === "" ||
        this.zoneBack === "" ||
        this.zoneGenitals === ""
      );
    },
    isSymtomsComplete() {
      return this.symptomItch === "" || this.symptomInsomnia === "";
    },
    isSignsComplete() {
      return (
        this.signsReedness === "" ||
        this.signsSwelling === "" ||
        this.signsScabs === "" ||
        this.signsMarks === "" ||
        this.signsLiquenification === "" ||
        this.signsDryness === ""
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.zoneHeadNeck = "";
      this.zoneSueperiorLimbsLeft = "";
      this.zoneSueperiorLimbsRight = "";
      this.zoneLowerLimbsLeft = "";
      this.zoneLowerLimbsRight = "";
      this.zoneTruk = "";
      this.zoneBack = "";
      this.zoneGenitals = "";
      this.signsReedness = "";
      this.signsSwelling = "";
      this.signsScabs = "";
      this.signsMarks = "";
      this.signsLiquenification = "";
      this.signsDryness = "";
      this.symptomItch = "";
      this.symptomInsomnia = "";
      this.activeStep = 0;
    },
    clear() {
      this.restoreData.zoneHeadNeck = this.zoneHeadNeck;
      this.restoreData.zoneSueperiorLimbsLeft = this.zoneSueperiorLimbsLeft;
      this.restoreData.zoneSueperiorLimbsRight = this.zoneSueperiorLimbsRight;
      this.restoreData.zoneLowerLimbsLeft = this.zoneLowerLimbsLeft;
      this.restoreData.zoneLowerLimbsRight = this.zoneLowerLimbsRight;
      this.restoreData.zoneTruk = this.zoneTruk;
      this.restoreData.zoneBack = this.zoneBack;
      this.restoreData.zoneGenitals = this.zoneGenitals;
      this.restoreData.signsReedness = this.signsReedness;
      this.restoreData.signsSwelling = this.signsSwelling;
      this.restoreData.signsScabs = this.signsScabs;
      this.restoreData.signsMarks = this.signsMarks;
      this.restoreData.signsLiquenification = this.signsLiquenification;
      this.restoreData.signsDryness = this.signsDryness;
      this.restoreData.symptomItch = this.symptomItch;
      this.restoreData.symptomInsomnia = this.symptomInsomnia;

      this.initialAnswer();
      this.clearTemporalSurveys();
      this.showUndoDialog("Se limpiaron los datos de  la encuesta SCORAD.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);

        let data = {
          surveyCode: "SCORAD",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let zoneHeadNeckAns = null;
      let zoneSueperiorLimbsLeftAns = null;
      let zoneSueperiorLimbsRightAns = null;
      let zoneLowerLimbsLeftAns = null;
      let zoneLowerLimbsRightAns = null;
      let zoneTrukAns = null;
      let zoneBackAns = null;
      let zoneGenitalsAns = null;
      let signsReednessAns = null;
      let signsSwellingAns = null;
      let signsScabsAns = null;
      let signsMarksAns = null;
      let signsLiquenificationAns = {};
      let signsDrynessAns = null;
      let symptomItchAns = null;
      let symptomInsomniaAns = null;

      Object.entries(this.answers.ZONA.ZONA_CABEZA_CUELLO).forEach((key) => {
        if (key[1] == this.zoneHeadNeck) {
          zoneHeadNeckAns = {
            questionCode: this.questions.ZONA.SCORAD_ZONA_CABEZA_CUELLO,
            answerCode: key[0],
            value: this.zoneHeadNeck,
          };
        }
      });

      Object.entries(
        this.answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA
      ).forEach((key) => {
        if (key[1] == this.zoneSueperiorLimbsLeft) {
          zoneSueperiorLimbsLeftAns = {
            questionCode: this.questions.ZONA
              .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA,
            answerCode: key[0],
            value: this.zoneSueperiorLimbsLeft,
          };
        }
      });

      Object.entries(
        this.answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_DERECHA
      ).forEach((key) => {
        if (key[1] == this.zoneSueperiorLimbsRight) {
          zoneSueperiorLimbsRightAns = {
            questionCode: this.questions.ZONA
              .SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA,
            answerCode: key[0],
            value: this.zoneSueperiorLimbsRight,
          };
        }
      });

      Object.entries(
        this.answers.ZONA.ZONA_MIEMBROS_INFERIORES_IZQUIERDA
      ).forEach((key) => {
        if (key[1] == this.zoneLowerLimbsLeft) {
          zoneLowerLimbsLeftAns = {
            questionCode: this.questions.ZONA
              .SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA,
            answerCode: key[0],
            value: this.zoneLowerLimbsLeft,
          };
        }
      });

      Object.entries(
        this.answers.ZONA.ZONA_MIEMBROS_INFERIORES_DERECHA
      ).forEach((key) => {
        if (key[1] == this.zoneLowerLimbsRight) {
          zoneLowerLimbsRightAns = {
            questionCode: this.questions.ZONA
              .SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA,
            answerCode: key[0],
            value: this.zoneLowerLimbsRight,
          };
        }
      });

      Object.entries(this.answers.ZONA.ZONA_TRONCO_ANTERIOR).forEach((key) => {
        if (key[1] == this.zoneTruk) {
          zoneTrukAns = {
            questionCode: this.questions.ZONA.SCORAD_ZONA_TRONCO_ANTERIOR,
            answerCode: key[0],
            value: this.zoneTruk,
          };
        }
      });

      Object.entries(this.answers.ZONA.ZONA_ESPALDA).forEach((key) => {
        if (key[1] == this.zoneBack) {
          zoneBackAns = {
            questionCode: this.questions.ZONA.SCORAD_ZONA_ESPALDA,
            answerCode: key[0],
            value: this.zoneBack,
          };
        }
      });

      Object.entries(this.answers.ZONA.ZONA_GENITALES).forEach((key) => {
        if (key[1] == this.zoneGenitals) {
          zoneGenitalsAns = {
            questionCode: this.questions.ZONA.SCORAD_ZONA_GENITALES,
            answerCode: key[0],
            value: this.zoneGenitals,
          };
        }
      });

      Object.entries(this.answers.SIGNOS.SIGNOS_ROJEZ).forEach((key) => {
        if (key[1] == this.signsReedness) {
          signsReednessAns = {
            questionCode: this.questions.SIGNOS.SCORAD_SIGNOS_ROJEZ,
            answerCode: key[0],
            value: this.signsReedness,
          };
        }
      });

      Object.entries(this.answers.SIGNOS.SIGNOS_HINCHAZON).forEach((key) => {
        if (key[1] == this.signsSwelling) {
          signsSwellingAns = {
            questionCode: this.questions.SIGNOS.SCORAD_SIGNOS_HINCHAZON,
            answerCode: key[0],
            value: this.signsSwelling,
          };
        }
      });

      Object.entries(this.answers.SIGNOS.SIGNOS_COSTRAS).forEach((key) => {
        if (key[1] == this.signsScabs) {
          signsScabsAns = {
            questionCode: this.questions.SIGNOS.SCORAD_SIGNOS_COSTRAS,
            answerCode: key[0],
            value: this.signsScabs,
          };
        }
      });

      Object.entries(this.answers.SIGNOS.SIGNOS_MARCAS).forEach((key) => {
        if (key[1] == this.signsMarks) {
          signsMarksAns = {
            questionCode: this.questions.SIGNOS.SCORAD_SIGNOS_MARCAS,
            answerCode: key[0],
            value: this.signsMarks,
          };
        }
      });

      Object.entries(this.answers.SIGNOS.SIGNOS_LIQUENIFICACION).forEach(
        (key) => {
          if (key[1] == this.signsLiquenification) {
            signsLiquenificationAns = {
              questionCode: this.questions.SIGNOS.SCORAD_SIGNOS_LIQUENIFICACION,
              answerCode: key[0],
              value: this.signsLiquenification,
            };
          }
        }
      );

      Object.entries(this.answers.SIGNOS.SIGNOS_SEQUEDAD).forEach((key) => {
        if (key[1] == this.signsDryness) {
          signsDrynessAns = {
            questionCode: this.questions.SIGNOS.SCORAD_SIGNOS_SEQUEDAD,
            answerCode: key[0],
            value: this.signsDryness,
          };
        }
      });
      Object.entries(this.answers.SINTOMAS.SINTOMAS_PRURITO).forEach((key) => {
        if (key[1] == this.symptomItch) {
          symptomItchAns = {
            questionCode: this.questions.SINTOMAS.SCORAD_SINTOMAS_PRURITO,
            answerCode: key[0],
            value: this.symptomItch,
          };
        }
      });

      Object.entries(this.answers.SINTOMAS.SINTOMAS_INSOMNIO).forEach((key) => {
        if (key[1] == this.symptomInsomnia) {
          symptomInsomniaAns = {
            questionCode: this.questions.SINTOMAS.SCORAD_SINTOMAS_INSOMNIO,
            answerCode: key[0],
            value: this.symptomInsomnia,
          };
        }
      });

      return [
        zoneHeadNeckAns,
        zoneSueperiorLimbsLeftAns,
        zoneSueperiorLimbsRightAns,
        zoneLowerLimbsLeftAns,
        zoneLowerLimbsRightAns,
        zoneTrukAns,
        zoneBackAns,
        zoneGenitalsAns,
        signsReednessAns,
        signsSwellingAns,
        signsScabsAns,
        signsMarksAns,
        signsLiquenificationAns,
        signsDrynessAns,
        symptomItchAns,
        symptomInsomniaAns,
      ];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.zoneHeadNeck = this.restoreData.zoneHeadNeck;
      this.zoneSueperiorLimbsLeft = this.restoreData.zoneSueperiorLimbsLeft;
      this.zoneSueperiorLimbsRight = this.restoreData.zoneSueperiorLimbsRight;
      this.zoneLowerLimbsLeft = this.restoreData.zoneLowerLimbsLeft;
      this.zoneLowerLimbsRight = this.restoreData.zoneLowerLimbsRight;
      this.zoneTruk = this.restoreData.zoneTruk;
      this.zoneBack = this.restoreData.zoneBack;
      this.zoneGenitals = this.restoreData.zoneGenitals;
      this.signsReedness = this.restoreData.signsReedness;
      this.signsSwelling = this.restoreData.signsSwelling;
      this.signsScabs = this.restoreData.signsScabs;
      this.signsMarks = this.restoreData.signsMarks;
      this.signsLiquenification = this.restoreData.signsLiquenification;
      this.signsDryness = this.restoreData.signsDryness;
      this.symptomItch = this.restoreData.symptomItch;
      this.symptomInsomnia = this.restoreData.symptomInsomnia;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "SCORAD",
          route: "Scorad",
        },
        survey: {
          zoneHeadNeck: this.zoneHeadNeck,
          zoneSueperiorLimbsLeft: this.zoneSueperiorLimbsLeft,
          zoneSueperiorLimbsRight: this.zoneSueperiorLimbsRight,
          zoneLowerLimbsLeft: this.zoneLowerLimbsLeft,
          zoneLowerLimbsRight: this.zoneLowerLimbsRight,
          zoneTruk: this.zoneTruk,
          zoneBack: this.zoneBack,
          zoneGenitals: this.zoneGenitals,
          signsReedness: this.signsReedness,
          signsSwelling: this.signsSwelling,
          signsScabs: this.signsScabs,
          signsMarks: this.signsMarks,
          signsLiquenification: this.signsLiquenification,
          signsDryness: this.signsDryness,
          symptomItch: this.symptomItch,
          symptomInsomnia: this.symptomInsomnia,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "SCORAD_ZONA_CABEZA_CUELLO") {
          this.zoneHeadNeck = parseFloat(survey.value);
        }

        if (
          survey.question.code ==
          "SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA"
        ) {
          this.zoneSueperiorLimbsLeft = parseFloat(survey.value);
        }

        if (
          survey.question.code == "SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA"
        ) {
          this.zoneSueperiorLimbsRight = parseFloat(survey.value);
        }

        if (
          survey.question.code == "SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA"
        ) {
          this.zoneLowerLimbsLeft = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA") {
          this.zoneLowerLimbsRight = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_ZONA_TRONCO_ANTERIOR") {
          this.zoneTruk = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_ZONA_ESPALDA") {
          this.zoneBack = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_ZONA_GENITALES") {
          this.zoneGenitals = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SIGNOS_ROJEZ") {
          this.signsReedness = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SIGNOS_HINCHAZON") {
          this.signsSwelling = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SIGNOS_COSTRAS") {
          this.signsScabs = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SIGNOS_MARCAS") {
          this.signsMarks = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SIGNOS_LIQUENIFICACION") {
          this.signsLiquenification = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SIGNOS_SEQUEDAD") {
          this.signsDryness = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SINTOMAS_PRURITO") {
          this.symptomItch = parseFloat(survey.value);
        }

        if (survey.question.code == "SCORAD_SINTOMAS_INSOMNIO") {
          this.symptomInsomnia = parseFloat(survey.value);
        }
      });
    },
    initialAnswer() {
      this.zoneHeadNeck = this.answers.ZONA.ZONA_CABEZA_CUELLO.SCORAD_ZONA_CABEZA_CUELLO_R1;
      this.zoneSueperiorLimbsLeft = this.answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R1;
      this.zoneSueperiorLimbsRight = this.answers.ZONA.ZONA_EXTREMIDADES_SUPERIORES_DERECHA.SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R1;
      this.zoneLowerLimbsLeft = this.answers.ZONA.ZONA_MIEMBROS_INFERIORES_IZQUIERDA.SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R1;
      this.zoneLowerLimbsRight = this.answers.ZONA.ZONA_MIEMBROS_INFERIORES_DERECHA.SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R1;
      this.zoneTruk = this.answers.ZONA.ZONA_TRONCO_ANTERIOR.SCORAD_ZONA_TRONCO_ANTERIOR_R1;
      this.zoneBack = this.answers.ZONA.ZONA_ESPALDA.SCORAD_ZONA_ESPALDA_R1;
      this.zoneGenitals = this.answers.ZONA.ZONA_GENITALES.SCORAD_ZONA_GENITALES_R1;
      this.signsReedness = this.answers.SIGNOS.SIGNOS_ROJEZ.SCORAD_SIGNOS_ROJEZ_R1;
      this.signsSwelling = this.answers.SIGNOS.SIGNOS_HINCHAZON.SCORAD_SIGNOS_HINCHAZON_R1;
      this.signsScabs = this.answers.SIGNOS.SIGNOS_COSTRAS.SCORAD_SIGNOS_COSTRAS_R1;
      this.signsMarks = this.answers.SIGNOS.SIGNOS_MARCAS.SCORAD_SIGNOS_MARCAS_R1;
      this.signsLiquenification = this.answers.SIGNOS.SIGNOS_LIQUENIFICACION.SCORAD_SIGNOS_LIQUENIFICACION_R1;
      this.signsDryness = this.answers.SIGNOS.SIGNOS_SEQUEDAD.SCORAD_SIGNOS_SEQUEDAD_R1;
      this.symptomItch = this.answers.SINTOMAS.SINTOMAS_PRURITO.SCORAD_SINTOMAS_PRURITO_R1;
      this.symptomInsomnia = this.answers.SINTOMAS.SINTOMAS_INSOMNIO.SCORAD_SINTOMAS_INSOMNIO_R1;
    },
  },
};
</script>
